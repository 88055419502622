import React from "react";
import { Button } from "@mui/material";

const FileUploadButton = ({ handleFileChange }) => {
  return (
    <div>
      <input
        type="file"
        accept=".mp3, .wav"
        multiple
        onChange={handleFileChange}
        style={{ display: "none" }} // Hide the actual file input
        id="mp3-upload"
      />
      <label htmlFor="mp3-upload">
        <Button
          variant="contained"
          component="span"
          sx={{
            backgroundColor: "#588d67", // Initial background color
            color: "white",             // Text color
            padding: "10px 20px",
            "&:hover": {
              backgroundColor: "#1c3930", // Background color on hover
            },
          }}
        >
          Upload
        </Button>
      </label>
    </div>
  );
};

export default FileUploadButton;
