import { alpha } from '@mui/material/styles';
import { red } from '@mui/material/colors';

export const green = {
  50: '#F6FEF6',
  100: '#E3FBE3',
  200: '#C7F7C7',
  300: '#A1E8A1',
  400: '#78bc74', // Light Green
  500: '#1DB954', // Spotify Green
  600: '#1AA347',
  700: '#1c3930',
  800: '#042F04',
  900: '#021D02',
};

export const offwhite = {
  50: '#ffffff',
  100: '#f4f4ec', // Offwhite
  200: '#f5f7f8', // Offwhite 2
  300: '#e8e8e8',
  400: '#d0d0d0',
  500: '#b8b8b8',
  600: '#a0a0a0',
  700: '#888888',
  800: '#707070',
  900: '#585858',
};

export const mustard = {
  500: '#eecf49', // Mustard
};

export const shadow = {
  500: '#45474b', // Shadow
};

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    primary: {
      light: green[400],
      main: green[500],
      dark: green[700],
      contrastText: offwhite[100],
      ...(mode === 'dark' && {
        light: green[400],
        main: green[500], // Spotify Green as primary accent
        dark: green[600],
        contrastText: offwhite[100],
      }),
    },
    secondary: {
      light: mustard[500],
      main: mustard[500],
      dark: mustard[500],
      ...(mode === 'dark' && {
        light: mustard[500],
        main: mustard[500],
        dark: mustard[500],
      }),
    },
    warning: {
      main: mustard[500],
      dark: mustard[500],
      ...(mode === 'dark' && { main: mustard[500], dark: mustard[500] }),
    },
    error: {
      light: red[50],
      main: red[500],
      dark: red[700],
      ...(mode === 'dark' && { light: '#D32F2F', main: '#D32F2F', dark: '#B22A2A' }),
    },
    success: {
      light: green[300],
      main: green[400],
      dark: green[700],
      ...(mode === 'dark' && {
        light: green[400],
        main: green[500],
        dark: green[600],
      }),
    },
    grey: {
      50: offwhite[100],
      100: offwhite[200],
      200: shadow[500],
      300: shadow[500],
      400: shadow[500],
      500: shadow[500],
      600: shadow[500],
      700: shadow[500],
      800: shadow[500],
      900: shadow[500],
    },
    divider: mode === 'dark' ? alpha(offwhite[600], 0.3) : alpha(offwhite[300], 0.5),
    background: {
      default: mode === 'dark' ? '#121212' : offwhite[100],
      paper: mode === 'dark' ? '#1E1E1E' : offwhite[200],
    },
    text: {
      primary: mode === 'dark' ? offwhite[100] : green[700],
      secondary: mode === 'dark' ? offwhite[200] : shadow[500],
    },
    action: {
      selected: mode === 'dark' ? alpha(green[600], 0.2) : `${alpha(green[200], 0.2)}`,
      ...(mode === 'dark' && {
        selected: alpha(green[600], 0.2),
      }),
    },
  },
  typography: {
    fontFamily: ['"Inter", "sans-serif"'].join(','),
    h1: {
      fontSize: 60,
      fontWeight: 600,
      lineHeight: 78 / 70,
      letterSpacing: -0.2,
      color: mode === 'dark' ? offwhite[100] : undefined,
    },
    h2: {
      fontSize: 48,
      fontWeight: 600,
      lineHeight: 1.2,
      color: mode === 'dark' ? offwhite[100] : undefined,
    },
    h3: {
      fontSize: 42,
      lineHeight: 1.2,
      color: mode === 'dark' ? offwhite[100] : undefined,
    },
    h4: {
      fontSize: 36,
      fontWeight: 500,
      lineHeight: 1.5,
      color: mode === 'dark' ? offwhite[100] : undefined,
    },
    h5: {
      fontSize: 20,
      fontWeight: 600,
      color: mode === 'dark' ? offwhite[100] : undefined,
    },
    h6: {
      fontSize: 18,
      color: mode === 'dark' ? offwhite[100] : undefined,
    },
    subtitle1: {
      fontSize: 18,
      color: mode === 'dark' ? offwhite[200] : undefined,
    },
    subtitle2: {
      fontSize: 16,
      color: mode === 'dark' ? offwhite[200] : undefined,
    },
    body1: {
      fontWeight: 400,
      fontSize: 15,
      color: mode === 'dark' ? offwhite[200] : undefined,
    },
    body2: {
      fontWeight: 400,
      fontSize: 14,
      color: mode === 'dark' ? offwhite[200] : undefined,
    },
    caption: {
      fontWeight: 400,
      fontSize: 12,
      color: mode === 'dark' ? offwhite[200] : undefined,
    },
  },
  components: {
    MuiAccordion: {
      defaultProps: {
        elevation: 0,
        disableGutters: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          padding: 8,
          overflow: 'clip',
          backgroundColor: mode === 'dark' ? '#1E1E1E' : offwhite[100],
          border: '1px solid',
          borderColor: mode === 'dark' ? green[600] : green[200],
          ':before': {
            backgroundColor: 'transparent',
          },
          '&:first-of-type': {
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          },
          '&:last-of-type': {
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          },
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            color: mode === 'dark' ? offwhite[100] : undefined,
            '&::placeholder': {
              color: mode === 'dark' ? offwhite[200] : offwhite[600],
              opacity: 1,
            },
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: mode === 'dark' ? offwhite[600] : undefined,
            },
            '&:hover fieldset': {
              borderColor: mode === 'dark' ? green[500] : undefined,
            },
            '&.Mui-focused fieldset': {
              borderColor: mode === 'dark' ? green[500] : undefined,
            },
          },
          '& .MuiInputLabel-root': {
            color: mode === 'dark' ? offwhite[200] : undefined,
            '&.Mui-focused': {
              color: mode === 'dark' ? green[500] : undefined,
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          textTransform: 'none',
          ...(ownerState.variant === 'contained' && {
            backgroundColor: mode === 'dark' ? green[500] : undefined,
            color: mode === 'dark' ? offwhite[100] : undefined,
            '&:hover': {
              backgroundColor: mode === 'dark' ? green[600] : undefined,
            },
          }),
          ...(ownerState.variant === 'outlined' && {
            borderColor: mode === 'dark' ? green[500] : undefined,
            color: mode === 'dark' ? green[500] : undefined,
            '&:hover': {
              borderColor: mode === 'dark' ? green[600] : undefined,
              backgroundColor: mode === 'dark' ? alpha(green[500], 0.1) : undefined,
            },
          }),
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: mode === 'dark' ? '#1E1E1E' : offwhite[200],
          color: mode === 'dark' ? offwhite[100] : undefined,
          boxShadow: mode === 'dark' ? 'none' : '0px 4px 6px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    // Add more component overrides as needed...
  },
});

export default function getLPTheme(mode) {
    return {
      ...getDesignTokens(mode),
      components: {
      MuiAccordion: {
        defaultProps: {
          elevation: 0,
          disableGutters: true,
        },
        styleOverrides: {
          root: ({ theme }) => ({
            padding: 8,
            overflow: 'clip',
            backgroundColor: offwhite[100],
            border: '1px solid',
            borderColor: green[200],
            ':before': {
              backgroundColor: 'transparent',
            },
            '&:first-of-type': {
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            },
            '&:last-of-type': {
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
            },
            ...(theme.palette.mode === 'dark' && {
              backgroundColor: green[900], // Slightly lighter background
              borderColor: green[600], // Lighter border
            }),
          }),
        },
      },
      // Other components with similar style overrides...
    },
  };
}
