import React, { useState } from "react";
import {
  Box,
  Link,
  Typography,
  useTheme,
  useMediaQuery,
  Collapse,
  Alert,
  TextField,
  Button,
  Card,
  Stack,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import axios from '../../utils/axios';
import { useNavigate } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CustomAccordion from "../CustomAccordian"; // Import the custom accordion
import MP3FileUploadButton from "../MP3FileUploadButton"; // Import the custom file upload button

const AudioScreen = () => {
  const theme = useTheme();
  const isNotMobile = useMediaQuery("(min-width: 1000px)");
  const navigate = useNavigate();

  const [text, setText] = useState("");
  const [file, setFile] = useState("");
  const [error, setError] = useState("");
  const [summary, setSummary] = useState("");
  const [loading, setLoading] = useState(false); // Loading state for transcription progress
  const [loadingSummary, setLoadingSummary] = useState(false); // Loading state for summary progress

  const handleCopyToClipboard = () => {
    if (summary) {
      const allInsights = summary.split(/(?=\d+\.)/).join("\n");
      navigator.clipboard.writeText(allInsights).catch((err) => {
        console.error("Failed to copy: ", err);
      });
    }
  };

  const summaryHandler = async (e) => {
    e.preventDefault();
    setLoadingSummary(true); // Set loading to true when summary processing starts

    try {
      const { data } = await axios.post("/api/openai/summaryCall", { text });
      console.log(data);
      setSummary(data);
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data.error) {
        setError(error.response.data.error);
      } else if (error.message) {
        setError(error.message);
      }
      setTimeout(() => {
        setError("");
      }, 5000);
    } finally {
      setLoadingSummary(false); // Set loading to false after processing is done
    }
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setLoading(true); // Set loading to true

      const processFile = async (file, index) => {
        const fileType = file.type;
        if (fileType === "audio/mpeg" || fileType === "audio/wav") {
          const fileArrayBuffer = await file.arrayBuffer();
          console.log(fileArrayBuffer);
          let transcription = `Call ${index + 1}:\n`;

          const getTranscriptionFromFile = async () => {
            try {
              const response = await axios.post("/api/openai/transcribe", {
                file: fileArrayBuffer
              }, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              });
              transcription += response.data.transcription + '\n'; // Append transcription to the call's text
            } catch (error) {
              setError(`Error transcribing audio file #${index + 1}: ${error.message}`);
            }
          };
  
          await getTranscriptionFromFile();
  
          setText(prevText => prevText + transcription + "\n\n"); // Append the transcription with heading to the overall text
          console.log("Text: ", text, '\n\n');
        } else {
          setError(`Unsupported file format for file ${index + 1}. Please upload an .mp3 or .wav audio file.`);
        }
      };
  
      const processAllFiles = async () => {
        for (let i = 0; i < files.length; i++) {
          await processFile(files[i], i);
        }
        setLoading(false); // Set loading to false after all files are processed
      };
  
      processAllFiles();
    }
  };

  return (
    <Box
      width={isNotMobile ? "50%" : "90%"}
      p="2rem"
      m="2rem auto"
      borderRadius={5}
      backgroundColor={theme.palette.background.alt}
      sx={{ boxShadow: 5 }}
    >
      <Collapse in={error}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      </Collapse>
      <form onSubmit={summaryHandler}>
        <Typography variant="h3" mb={2}>
          Customer Service Call Analyzer
        </Typography>
        <Stack direction="row" spacing={1}>
          <Box width="87%">
            <TextField
              placeholder="Enter the script of a call you want to analyze here"
              required
              fullWidth
              value={text}
              onChange={(e) => setText(e.target.value)}
            ></TextField>
          </Box>
          <Button
            disableElevation
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: "#588d67", // Initial background color
              color: "white", // Text color
              "&:hover": {
                backgroundColor: "#1c3930", // Background color on hover
              },
            }}
          >
            Analyze
          </Button>
        </Stack>
        <Typography variant="h6" mt={2}>
          Or upload an audio file:
        </Typography>
        <MP3FileUploadButton handleFileChange={handleFileChange} />
      </form>
      {loading && (
        <Box sx={{ width: "100%", mt: 2 }}>
          <LinearProgress />
        </Box>
      )}
      {loadingSummary && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      )}
      {summary ? (
        <Box sx={{ mt: 4 }}>
          <Button
            variant="contained"
            startIcon={<ContentCopyIcon />}
            onClick={handleCopyToClipboard}
            sx={{
              mt: -2,
              mb: 2,
              backgroundColor: "#588d67", // Initial background color
              color: "white", // Text color
              "&:hover": {
                backgroundColor: "#1c3930", // Background color on hover
              },
            }}
          >
            <Typography variant="button" sx={{ color: "white" }}>
              Copy Insights
            </Typography>
          </Button>
          <CustomAccordion summary={summary} />{" "}
          {/* Use the custom accordion component */}
        </Box>
      ) : (
        !loadingSummary && ( // Ensure the placeholder is not shown while loading summary
          <Card
            sx={{
              mt: 4,
              p: 2,
              border: 1,
              boxShadow: 0,
              borderColor: "neutral.medium",
              borderRadius: 2,
              height: "500px",
              bgcolor: "background.default",
            }}
          >
            <Typography
              variant="h3"
              color="neutral.main"
              sx={{
                textAlign: "center",
                verticalAlign: "middle",
                lineHeight: "450px",
              }}
            >
              Insights will appear here
            </Typography>
          </Card>
        )
      )}
      <Typography mt={2}>
        Not the tool you were looking for? <Link href="/home">Go back</Link>
      </Typography>
    </Box>
  );
};

export default AudioScreen;
