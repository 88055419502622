import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Link,
  Typography,
  Button,
  Drawer,
  CssBaseline,
  Toolbar,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import { useNavigate } from "react-router-dom";

import axios from "../utils/axios";



const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: "#1c3930", // Set the dark green color
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Navbar = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(""); 
  const [loggedIn, setLoggedIn] = useState(
    JSON.parse(localStorage.getItem("authToken"))
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logoutHandler = async () => {
    try {
      await axios.post("/api/auth/logout").then((res) => fullyLogout(res.data));
    } catch (error) {
      console.log(error);
    }
  };

  const fullyLogout = (data) => {
    if (data.success) {
      localStorage.removeItem("authToken");
      window.location.reload();
    }
  };

  const createPortal = async () => {
    try {
      const token = await axios.get("/api/auth/refresh-token");
      if (token.data) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${String(token.data.accessToken)}`,
          },
        };
        const customerId = await axios.get("/api/auth/customer", config);
        if (customerId.data.customerId) {
          const portal = await axios.post(
            "/api/stripe/portal",
            { customerId: customerId.data.customerId },
            config
          );
          if (portal.data.url) {
            window.open(portal.data.url, "_self");
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCheckoutSummary = async (e) => {
    e.preventDefault();
    try{
        const token = await axios.get("/api/auth/refresh-token");
        console.log("token: ", token);
        if (token.data){
            const config = {headers: {"Content-Type": "application/json", "Authorization": `Bearer ${String(token.data.accessToken)}`}};
            const sub = await axios.get("/api/auth/subscription", config);
            if (sub.data.subscription) {
                navigate("/summary");
            } else {
                const session = await axios.post("/api/stripe/checkout", {priceId: "price_1PsBhdLyZT00OH0lZowtYY2J", sub: "normal"}, config);
                console.log("session: ", session);
                if (session.data) {
                    console.log("session.data.url: ", session.data.url);    
                    window.open(session.data.url, "_self");
                }
            }
        } else {
            setError("Please login to continue");
        }
    } catch(err) {
        console.log("Error in handleCheckoutSummary");
        if (err.response.data.message) {
            setError(err.response.data.message);
            setTimeout(() => { setError("")}, 3000);
        } else if (err.message) {
            setError(err.message);
            setTimeout(() => { setError("")}, 3000);
        }
    }
  }
  
  const handleCheckoutAudio = async (e) => {
    e.preventDefault();
    try{
        const token = await axios.get("/api/auth/refresh-token");
        if (token.data){
            const config = {headers: {"Content-Type": "application/json", "Authorization": `Bearer ${String(token.data.accessToken)}`}};
            const sub = await axios.get("/api/auth/subscription", config);
            if (sub.data.subscription) {
                navigate("/mp3Upload");
            } else {
                const session = await axios.post("/api/stripe/checkout", {priceId: "price_1PsBhdLyZT00OH0lZowtYY2J", sub: "normal"}, config);
                console.log("session: ", session);
                if (session.data) {
                    console.log("session.data.url: ", session.data.url);    
                    window.open(session.data.url, "_self");
                }
            }
        } else {
            setError("Please login to continue");
        }
    } catch(err) {
        console.log("Error in handleCheckoutAudio");
        if (err.response.data.message) {
            setError(err.response.data.message);
            setTimeout(() => { setError("")}, 3000);
        } else if (err.message) {
            setError(err.message);
            setTimeout(() => { setError("")}, 3000);
        }
    }
  }
  
  const handleCheckoutSales = async (e) => {
    e.preventDefault();
    try{
        const token = await axios.get("/api/auth/refresh-token");
        if (token.data){
            const config = {headers: {"Content-Type": "application/json", "Authorization": `Bearer ${String(token.data.accessToken)}`}};
            const sub = await axios.get("/api/auth/subscription", config);
            if (sub.data.subscription) {
                navigate("/salesCall");
            } else {
                const session = await axios.post("/api/stripe/checkout", {priceId: "price_1PsBhdLyZT00OH0lZowtYY2J", sub: "normal"}, config);
                console.log("session: ", session);
                if (session.data) {
                    console.log("session.data.url: ", session.data.url);    
                    window.open(session.data.url, "_self");
                }
            }
        } else {
            setError("Please login to continue");
        }
    } catch(err) {
        console.log("Error in handleCheckoutMarketing");
        if (err.response.data.message) {
            setError(err.response.data.message);
            setTimeout(() => { setError("")}, 3000);
        } else if (err.message) {
            setError(err.message);
            setTimeout(() => { setError("")}, 3000);
        }
    }
  }
  
  const handleCheckoutMarketingDocs = async (e) => {
    e.preventDefault();
    try{
        const token = await axios.get("/api/auth/refresh-token");
        if (token.data){
            const config = {headers: {"Content-Type": "application/json", "Authorization": `Bearer ${String(token.data.accessToken)}`}};
            const sub = await axios.get("/api/auth/subscription", config);
            if (sub.data.subscription) {
                navigate("/marketingDocs");
            } else {
                const session = await axios.post("/api/stripe/checkout", {priceId: "price_1PsBhdLyZT00OH0lZowtYY2J", sub: "normal"}, config);
                console.log("session: ", session);
                if (session.data) {
                    console.log("session.data.url: ", session.data.url);    
                    window.open(session.data.url, "_self");
                }
            }
        } else {
            setError("Please login to continue");
        }
    } catch(err) {
        console.log("Error in handleCheckoutMarketing");
        if (err.response.data.message) {
            setError(err.response.data.message);
            setTimeout(() => { setError("")}, 3000);
        } else if (err.message) {
            setError(err.message);
            setTimeout(() => { setError("")}, 3000);
        }
    }
  }

  return (
    
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <Box
            sx={{ flexGrow: 1, display: "inline-flex", alignItems: "center", cursor: "pointer" }}
            onClick={handleDrawerOpen} // Use LogoHead to open the drawer
          >
            <img
              src="/LogoHead.svg"
              alt="Dissect AI Logo"
              style={{
                width: 50,
                marginBottom: 0,
                marginRight: 10,
                verticalAlign: "middle",
              }}
            />
            <Typography
        variant="h3" // Adjusted variant to fit better within a navbar
        color="#ffffff"
        fontWeight="bold"
        sx={{ display: 'inline-flex', alignItems: 'center' }}
        >
              Dissect AI
            </Typography>
          </Box>
          <Box>
            {loggedIn ? (
              <>
                <Button onClick={createPortal} sx={{ color: "#ffffff" }}>
                  Billing Portal
                </Button>
                <Button onClick={logoutHandler} sx={{ color: "#ffffff" }}>
                  Logout
                </Button>
              </>
            ) : (
              <>
                <Link href="/register" p={1} color="#ffffff" sx={{ ml: 2 }}>
                  Register
                </Link>
                <Link href="/login" p={1} color="#ffffff" sx={{ ml: 2 }}>
                  Login
                </Link>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#1c3930", // Set the same dark green color as the navbar
            color: "#ffffff", // Make text white
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} sx={{ color: "#ffffff" }}>
            {theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Typography variant="h5" sx={{ color: "#ffffff", mt: 2, fontWeight: "bold", alignSelf: "center"}}>
          Document Analysis
        </Typography>
        <Divider sx={{ backgroundColor: "#ffffff" }} />
        <List>
      {["Reviews Analyzer", "Marketing Consultant"].map((text, index) => (
        <ListItem key={text} disablePadding>
          <ListItemButton
            onClick={index % 2 === 0 ? handleCheckoutSummary : handleCheckoutMarketingDocs}
          >
            <ListItemIcon sx={{ color: "#ffffff" }}>
              {index % 2 === 0 ? (
                <DescriptionRoundedIcon />
              ) : (
                <TrendingUpIcon />
              )}
            </ListItemIcon>
            <ListItemText primary={text} sx={{ color: "#ffffff" }} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
        <Typography variant="h5" sx={{ color: "#ffffff", mt: 2, fontWeight: "bold", alignSelf: "center"}}>
          Audio Analysis
        </Typography>
        <Divider sx={{ backgroundColor: "#ffffff" }} />
        <List>
          {["Customer Service Call", "Sales Call"].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon sx={{ color: "#ffffff" }}>
                  {index % 2 === 0 ? <SupportAgentIcon /> : <PointOfSaleIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ color: "#ffffff" }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main open={open}>

      </Main>
    </Box>
  );
};

export default Navbar;
