import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import AccessTime from '@mui/icons-material/AccessTime';
import Dashboard from '@mui/icons-material/Dashboard';
import Diamond from '@mui/icons-material/Diamond';
import Assignment from '@mui/icons-material/Assignment';
import Chat from '@mui/icons-material/Chat';
import Spa from '@mui/icons-material/Spa';

const items = [
  {
    icon: <AccessTime />,
    title: 'Time saver',
    description:
      'Instead of having to sit through hundreds of hours of calls or analyze hundreds of pages of text, get quick, accurate, and actionable insights with Dissect AI.',
  },
  {
    icon: <Dashboard />,
    title: 'All-In-One',
    description:
      'Your all-in-one business consultant for your sales, marketing, and customer service needs. ',
  },
  {
    icon: <Diamond />,
    title: 'Tap into your goldmine of data',
    description:
      'Most companies are sitting on a goldmine of customer data, but they don’t ever use it because it’s not a priority to analyze. Unlock the power of this data with DissectAI.',
  },
  {
    icon: <Assignment />,
    title: 'Specific feedback',
    description:
      'Our thorough analysis cites the exact places where we find issues in your business, allowing you to tackle the problems head-on',
  },
  {
  icon: <Chat />,
  title: 'Digestible insights',
  description:
    'Clear and concise insights for you to quickly analyze and act on.',
},
  {
    icon: <Spa />,
    title: 'Easy to use',
    description:
      'Extremely simple to use. just upload your files, and get your insights instantly',
  },
  
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#313638',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4" sx={{ color: 'white' }}>
            Highlights
          </Typography>
          <Typography variant="body1" sx={{ color: 'white' }}>
            Explore why our product stands out: adaptability, durability,
            user-friendly design, and innovation. Enjoy reliable customer support and
            precision in every detail.
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'grey.800',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                }}
              >
                <Box sx={{ opacity: 0.7 }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom sx={{ color: 'white' }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'white' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
