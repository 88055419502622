import { Routes, Route, useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { themeSettings } from './theme';
import Navbar from './components/Navbar';
import HomeScreen from './components/screens/HomeScreen';
import LoginScreen from './components/screens/LoginScreen';
import RegisterScreen from './components/screens/RegisterScreen';
import SummaryScreen from './components/screens/SummaryScreen';
import AudioScreen from './components/screens/UploadFileScreen';
import SalesCallScreen from './components/screens/SalesCall';
import LandingScreen from './components/screens/LandingScreen';
import SummaryMarketingDocs from './components/screens/SummaryMarketingDocs';
import MeetTheTeam from './components/screens/Team';
import ContactForm from './components/screens/ContactScreen';
import PrivacyScreen from './components/screens/PrivacyScreen';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

function App() {
  const theme = useMemo(() => createTheme(themeSettings()), []);
  const location = useLocation();

  return (
    <Elements stripe={stripePromise}>
      <div className="App">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {/* Conditionally render the Navbar */}
          {location.pathname !== '/' && <Navbar />}
          <Routes>
            <Route exact path="/home" element={<HomeScreen />} />
            <Route exact path="/register" element={<RegisterScreen />} />
            <Route exact path="/login" element={<LoginScreen />} />
            <Route exact path="/summary" element={<SummaryScreen />} />
            <Route exact path="/mp3Upload" element={<AudioScreen />} />
            <Route exact path="/salesCall" element={<SalesCallScreen />} />
            <Route exact path="/marketingDocs" element={<SummaryMarketingDocs />} />
            <Route exact path="/" element={<LandingScreen />} />
            <Route exact path="/team" element={<MeetTheTeam />} />
            <Route exact path="/contact" element={<ContactForm />} />
            <Route exact path="/privacy" element={<PrivacyScreen />} />
          </Routes>
        </ThemeProvider>
      </div>
    </Elements>
  );
}

export default App;
