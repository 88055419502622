import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CustomAccordion = ({ summary }) => {
  // Strip asterisks from the summary
  const cleanedSummary = summary.replace(/\*/g, "");

  return (
    <div>
      {cleanedSummary.split(/(?=\d+\.)/).map((item, index) => {
        // Split the string only at the first colon
        const colonIndex = item.indexOf(":");
        const summaryTitle =
          colonIndex !== -1 ? item.slice(0, colonIndex).trim() : `Insight ${index + 1}`;
        const detailText =
          colonIndex !== -1 ? item.slice(colonIndex + 1).trim() : "";

        return (
          <Accordion
            key={index}
            sx={{
              borderRadius: "8px", // Adjust this value for more or less rounding
              marginBottom: "8px", // Optional: adds spacing between accordions
              overflow: "hidden", // Ensures rounded corners for child elements
              backgroundColor: "white", // White background when not expanded
              "&:before": {
                display: "none", // Removes the default MUI divider line
              },
              "&.Mui-expanded": {
                backgroundColor: "#1c3930",
                color: "white",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "black" }} />} // Black icon when not expanded
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              sx={{
                borderTopLeftRadius: "8px", // Same as borderRadius above
                borderTopRightRadius: "8px", // Same as borderRadius above
                backgroundColor: "white", // White background when not expanded
                color: "black", // Black text color when not expanded
                "&.Mui-expanded": {
                  backgroundColor: "#1c3930",
                  color: "white",
                },
              }}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                {summaryTitle}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: "#1c3930",
                color: "white",
                borderBottomLeftRadius: "8px", // Same as borderRadius above
                borderBottomRightRadius: "8px", // Same as borderRadius above
              }}
            >
              <Typography>{detailText}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default CustomAccordion;
