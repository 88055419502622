import React from "react";
import { Button } from "@mui/material";

const FileUploadButton = ({ handleFileChange }) => {
  return (
    <div>
      <input
        type="file"
        accept=".txt, .pdf, .docx, .xlsx, .csv"
        onChange={handleFileChange}
        style={{ display: 'none' }} // Hide the actual file input
        id="file-input"
      />
      <label htmlFor="file-input">
        <Button
          variant="contained"
          component="span"
          sx={{
            backgroundColor: "#588d67",
            color: "white",
            padding: "10px 20px",
            "&:hover": {
              backgroundColor: "#1c3930",
            },
          }}
        >
          Upload File
        </Button>
      </label>
    </div>
  );
};

export default FileUploadButton;
