import React from 'react';
import { Box, Typography, Container, Button } from '@mui/material';

const PrivacyScreen = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
        <Button href="/" variant="contained" color="primary" sx={{ mb: 3 }}>
            Back to Home
        </Button>
      <Typography variant="h1" gutterBottom sx={{textAlign: 'center', fontWeight: 'bold'}}>
        Privacy Policy
      </Typography>

      <Box mb={3}>
        <Typography variant="h4" fontWeight= 'bold'>1. Introduction</Typography>
        <Typography>
          We value your privacy and are committed to protecting your personal and business information. This Privacy Policy outlines the types of information we collect, how we use it, and the measures we take to ensure your data is secure.
        </Typography>
      </Box>

      <Box mb={3}>
        <Typography variant="h4" fontWeight= 'bold'>2. Information We Collect</Typography>
        <Typography>
          <strong>Business Data:</strong> We collect data from businesses that is provided to us for analysis purposes using the OpenAI API. This may include text data, documents, or other forms of business-related information.
        </Typography>
        <Typography>
          <strong>Personal Information:</strong> We may collect personal information such as your name, email address, and contact details when you interact with our website, register for an account, or communicate with us.
        </Typography>
      </Box>

      <Box mb={3}>
        <Typography variant="h4" fontWeight= 'bold'>3. How We Use Your Information</Typography>
        <Typography>
          <strong>Business Data Analysis:</strong> The primary purpose of collecting business data is to provide analysis and insights through the OpenAI API. We do not store your business data beyond the duration required for analysis.
        </Typography>
        <Typography>
          <strong>Communication:</strong> We may use your contact information to communicate with you about our services, updates, and any inquiries you have made.
        </Typography>
        <Typography>
          <strong>Improvement of Services:</strong> Aggregated and anonymized data may be used to improve our services, algorithms, and the overall user experience.
        </Typography>
      </Box>

      <Box mb={3}>
        <Typography variant="h4" fontWeight= 'bold'>4. Data Sharing and Disclosure</Typography>
        <Typography>
          <strong>Third-Party Providers:</strong> We use the OpenAI API to analyze the business data you provide. OpenAI may process the data in accordance with its own privacy policies.
        </Typography>
        <Typography>
          <strong>Legal Compliance:</strong> We may disclose your information if required by law or if we believe that such action is necessary to comply with legal processes, protect our rights, or ensure the safety of our users.
        </Typography>
      </Box>

      <Box mb={3}>
        <Typography variant="h4" fontWeight= 'bold'>5. Data Security</Typography>
        <Typography>
          We implement industry-standard security measures to protect your data from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
        </Typography>
      </Box>

      <Box mb={3}>
        <Typography variant="h4" fontWeight= 'bold'>6. Data Retention</Typography>
        <Typography>
          We retain your business data only for as long as necessary to fulfill the purposes for which it was collected. Personal information is retained in accordance with applicable legal and regulatory requirements.
        </Typography>
      </Box>

      <Box mb={3}>
        <Typography variant="h4" fontWeight= 'bold'>7. Your Rights</Typography>
        <Typography>
          You have the right to access, correct, or delete your personal information held by us. If you wish to exercise these rights, please contact us at dissectaisolutions@gmail.com. We will respond to your request within a reasonable timeframe.
        </Typography>
      </Box>

      <Box mb={3}>
        <Typography variant="h4" fontWeight= 'bold'>8. Cookies and Tracking Technologies</Typography>
        <Typography>
          We may use cookies and similar tracking technologies to enhance your experience on our website. You can control the use of cookies through your browser settings.
        </Typography>
      </Box>

      <Box mb={3}>
        <Typography variant="h4" fontWeight= 'bold'>9. Changes to This Policy</Typography>
        <Typography>
          We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the new policy on our website.
        </Typography>
      </Box>

      <Box mb={3}>
        <Typography variant="h4" fontWeight= 'bold'>10. Contact Us</Typography>
        <Typography>
          If you have any questions or concerns about this Privacy Policy, please contact us at dissectaisolutions@gmail.com.
        </Typography>
      </Box>

      <Typography variant="h1" fontWeight='bold' textAlign={'center'} gutterBottom>
        Terms and Conditions
      </Typography>

      <Box mb={3}>
        <Typography variant="h4" fontWeight= 'bold'>1. Acceptance of Terms</Typography>
        <Typography>
          By accessing and using our website and services, you agree to be bound by these Terms and Conditions. If you do not agree to these terms, please do not use our services.
        </Typography>
      </Box>

      <Box mb={3}>
        <Typography variant="h4" fontWeight= 'bold'>2. Use of Services</Typography>
        <Typography>
          <strong>Permitted Use:</strong> You are permitted to use our services to analyze business data using the OpenAI API. You agree to provide accurate and lawful information.
        </Typography>
        <Typography>
          <strong>Prohibited Use:</strong> You may not use our services to submit any information that is unlawful, fraudulent, or infringes on the rights of others.
        </Typography>
      </Box>

      <Box mb={3}>
        <Typography variant="h4" fontWeight= 'bold'>3. Intellectual Property</Typography>
        <Typography>
          All content, trademarks, and intellectual property on our website are owned by us or licensed to us. You may not reproduce, distribute, or create derivative works from our content without our express permission.
        </Typography>
      </Box>

      <Box mb={3}>
        <Typography variant="h4" fontWeight= 'bold'>4. Limitation of Liability</Typography>
        <Typography>
          We are not liable for any direct, indirect, incidental, or consequential damages arising from your use of our services or the OpenAI API. This includes, but is not limited to, loss of profits, data, or business opportunities.
        </Typography>
      </Box>

      <Box mb={3}>
        <Typography variant="h4" fontWeight= 'bold'>5. Indemnification</Typography>
        <Typography>
          You agree to indemnify and hold us harmless from any claims, damages, or expenses (including legal fees) arising from your use of our services, your violation of these Terms and Conditions, or your infringement on the rights of any third party.
        </Typography>
      </Box>

      <Box mb={3}>
        <Typography variant="h4" fontWeight= 'bold'>6. Termination</Typography>
        <Typography>
          We reserve the right to terminate or suspend your access to our services at any time, without notice, for conduct that we believe violates these Terms and Conditions or is harmful to other users of our services.
        </Typography>
      </Box>

      <Box mb={3}>
        <Typography variant="h4" fontWeight= 'bold'>7. Governing Law</Typography>
        <Typography>
          These Terms and Conditions are governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising from these terms will be subject to the exclusive jurisdiction of the courts in [Your Jurisdiction].
        </Typography>
      </Box>

      <Box mb={3}>
        <Typography variant="h4" fontWeight= 'bold'>8. Changes to Terms</Typography>
        <Typography>
          We may update these Terms and Conditions from time to time. We will notify you of any significant changes by posting the new terms on our website. Your continued use of our services after any changes indicates your acceptance of the new terms.
        </Typography>
      </Box>

      <Box mb={3}>
        <Typography variant="h4" fontWeight= 'bold'>9. Contact Information</Typography>
        <Typography>
          If you have any questions or concerns about these Terms and Conditions, please contact us at dissectaisolutions@gmail.com.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyScreen;