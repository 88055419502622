import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";

const teamMembers = [
  {
    name: "Eshwar Kuppa",
    role: "CEO & Founder",
    image: "/eshwar.jpg", // Replace with actual image paths
    bio: "Eshwar is a second-year Business Information Management student at the University of California, Irvine. Being a business enthusiast his whole life, Eshwar loves to solve problems and create value. Eshwar intends to grow Dissect AI to become the go-to AI soluition for businesses that are looking to increase their productivity with data-driven insights. He hopes to leverage his extensive background in sales, marketing, and finance to break into the software sphere and take DissectAI to new heights.",
  },
  {
    name: "Justin Pham",
    role: "CTO & Founder",
    image: "/justin.jpg",
    bio: "Justin is a third-year Computer Science and Informatics student at the University of California, Irvine. With a passion to ideate, innovate, and create, Justin aspires to work in the fields of software engineering, product management, or entrepreneurship post-grad. As his first entrepreneurial venture, Justin is excited to lead Dissect AI's technical development and build a product that will revolutionize the way businesses analyze their data.",
  },
  // Add more team members as needed
];

const MeetTheTeam = () => {
  const theme = useTheme();
  const isNotMobile = useMediaQuery("(min-width: 1000px)");

  return (
    <Box
      width="100%"
      p="2rem"
      m="0 auto"
      borderRadius={0}
      backgroundColor="#f4f4ec"
      sx={{ boxShadow: 5, minHeight: '93.75vh' }}  // Ensures it spans to the bottom
    >
      <Button href="/" variant="contained" color="primary" sx={{ mb: 4 }}>
        Back to Home
      </Button>

      {/* Page Header */}
      <Typography fontWeight="bold" variant="h1" align="center" gutterBottom>
        Meet the Team
      </Typography>

      {/* Page Description */}
      <Typography variant="h6" align="center" mb={4}>
        Our team currently consists of ambitious university students with diverse skill sets and a shared passion for innovation.
      </Typography>

      {/* Team Members Grid */}
      <Grid container spacing={4} alignItems="stretch">
        {teamMembers.map((member, index) => (
          <Grid item xs={6} key={index}>
            <Card sx={{ display: "flex", height: "100%" }}>
              <CardMedia
                component="img"
                sx={{
                  width: "40%",
                  height: 350,
                  objectFit: "cover",
                  objectPosition: "center", // Centers the image within the 500x500 square
                }}
                image={member.image}
                alt={member.name}
              />
              <CardContent sx={{ width: "50%", display: "flex", flexDirection: "column", justifyContent: "center", p: 3 }}>
                <Typography variant="h3" fontWeight="bold" gutterBottom>
                  {member.name}
                </Typography>
                <Typography variant="h6" fontWeight="bold" color="textSecondary" gutterBottom>
                  {member.role}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {member.bio}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MeetTheTeam;
