import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import {Typography, Divider, Link} from '@mui/material';
import ToggleColorMode from '../ToggleColorMode';
import axios from '../../utils/axios';
import { useState, useEffect } from 'react';

const logoStyle = {
  width: '140px',
  height: 'auto',
  cursor: 'pointer',
};

function AppAppBar({ mode, toggleColorMode }) {
  const [open, setOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(
    JSON.parse(localStorage.getItem("authToken"))
  );

  useEffect(() => {
    const checkRefresh = async () => {
      try {
        const token = await axios.get("/api/auth/refresh-token");
        if (!token.data) {
          localStorage.removeItem("authToken");
          setLoggedIn(false);
          logoutHandler();
        }
      } catch (error) {
        console.log("Error in checkRefresh: ", error);
      }
    };

    checkRefresh();
  }, []);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
      setOpen(false);
    }
  };

  const logoutHandler = async () => {
    try {
      await axios.post("/api/auth/logout").then((res) => fullyLogout(res.data));
    } catch (error) {
      console.log(error);
    }
  };

  const fullyLogout = (data) => {
    if (data.success) {
      localStorage.removeItem("authToken");
      window.location.reload();
    }
  };

  const createPortal = async () => {
    try {
      const token = await axios.get("/api/auth/refresh-token");
      if (token.data) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${String(token.data.accessToken)}`,
          },
        };
        const customerId = await axios.get("/api/auth/customer", config);
        console.log("customerId: ", customerId);
        if (customerId.data.customerId) {
          const portal = await axios.post(
            "/api/stripe/portal",
            { customerId: customerId.data.customerId },
            config
          );
          if (portal.data.url) {
            window.open(portal.data.url, "_self");
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 2,
        }}
      >
        <Container maxWidth="lg">
        <Toolbar
  variant="regular"
  sx={(theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexShrink: 0,
    borderRadius: '999px',
    bgcolor:
      theme.palette.mode === 'light'
        ? 'rgba(244, 244, 236, 0.4)' // Offwhite with opacity
        : 'rgba(28, 57, 48, 0.4)', // Dark Green with opacity
    backdropFilter: 'blur(24px)',
    maxHeight: 40,
    border: '1px solid',
    borderColor: theme.palette.divider,
    boxShadow:
      theme.palette.mode === 'light'
        ? `0 0 1px rgba(120, 188, 116, 0.1), 1px 1.5px 2px -1px rgba(120, 188, 116, 0.15), 4px 4px 12px -2.5px rgba(120, 188, 116, 0.15)`
        : '0 0 1px rgba(72, 94, 87, 0.7), 1px 1.5px 2px -1px rgba(72, 94, 87, 0.65), 4px 4px 12px -2.5px rgba(72, 94, 87, 0.65)',
  })}
>

            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                ml: '-18px',
                px: 0,
              }}
            >
              <img
                src="/LogoHead.svg"
                alt="Dissect AI Logo"
                style={{ width: 50, marginBottom: 0, marginRight: 5, marginLeft:10, verticalAlign: 'middle' }}
              />
              <Typography variant="h5" color="text.primary" sx={{mr: 2,fontWeight: 'bold'}}>
                    Dissect AI
              </Typography>
              
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <MenuItem
                  onClick={() => scrollToSection('features')}
                  sx={{ py: '6px', px: '12px' }}  
                >
                  <Typography variant="body2" color="text.primary">
                    Features
                  </Typography>
                </MenuItem>
                {/* <MenuItem
                  onClick={() => scrollToSection('testimonials')}
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography variant="body2" color="text.primary">
                    Testimonials
                  </Typography>
                </MenuItem> */}
                <MenuItem
                  onClick={() => scrollToSection('highlights')}
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography variant="body2" color="text.primary">
                    Highlights
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection('pricing')}
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography variant="body2" color="text.primary">
                    Pricing
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection('faq')}
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography variant="body2" color="text.primary">
                    FAQ
                  </Typography>
                </MenuItem>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 0.5,
                alignItems: 'center',
              }}
            >
              <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
              {loggedIn ? (
                <>
                  <Button
                    color="primary"
                    variant="text"
                    size="small"
                    onClick={createPortal}
                  >
                    Billing Portal
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={logoutHandler}
                  >
                    Logout
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    color="primary"
                    variant="text"
                    size="small"
                    component="a"
                    href="/login"
                  >
                    Sign in
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    component="a"
                    href="/register"
                  >
                    Sign up
                  </Button>
                </>
              )}
            </Box>
            <Box sx={{ display: { sm: '', md: 'none' } }}>
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: '30px', p: '4px' }}
              >
                <MenuIcon />
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: '60dvw',
                    p: 2,
                    backgroundColor: 'background.paper',
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'end',
                      flexGrow: 1,
                    }}
                  >
                    <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
                  </Box>
                  <MenuItem onClick={() => scrollToSection('features')}>
                    Features
                  </MenuItem>
                  {/* <MenuItem onClick={() => scrollToSection('testimonials')}>
                    Testimonials
                  </MenuItem> */}
                  <MenuItem onClick={() => scrollToSection('highlights')}>
                    Highlights
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection('pricing')}>
                    Pricing
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection('faq')}>FAQ</MenuItem>
                  <Divider />
                  {loggedIn ? (
                    <>
                      <MenuItem>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={createPortal}
                          sx={{ width: '100%' }}
                        >
                          Billing Portal
                        </Button>
                      </MenuItem>
                      <MenuItem>
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={logoutHandler}
                          sx={{ width: '100%' }}
                        >
                          Logout
                        </Button>
                      </MenuItem>
                    </>
                  ) : (
                    <>
                      <MenuItem>
                        <Button
                          color="primary"
                          variant="contained"
                          component="a"
                          href="/material-ui/getting-started/templates/sign-up/"
                          target="_blank"
                          sx={{ width: '100%' }}
                        >
                          Sign up
                        </Button>
                      </MenuItem>
                      <MenuItem>
                        <Button
                          color="primary"
                          variant="outlined"
                          component="a"
                          href="/material-ui/getting-started/templates/sign-in/"
                          target="_blank"
                          sx={{ width: '100%' }}
                        >
                          Sign in
                        </Button>
                      </MenuItem>
                    </>
                  )}
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;
