import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import {Collapse, Alert} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from '../../utils/axios';
import { useState } from 'react';

const tiers = [
  {
    title: 'Professional',
    subheader: 'Recommended',
    price: '59.99',
    description: [
      'Up to 1,000 hours of audio anaylsis per month', 
      'Up to 1,000,000 words of text analysis per month',
      'Early access to beta features',
      '24/7 Customer Support',
    ],
    buttonText: 'Start now',
    buttonVariant: 'contained',
  },
  {
    title: 'Enterprise',
    // subheader: 'Recommended',
    price: 'Contact sales',
    description: [
      'Custom trained model, tailored to your business standards',
      'Unlimited hours of audio anaylsis', 
      'Unlimited words of text analysis',
      'Priority access to new features',
      '24/7 Customer Support',
    ],
    buttonText: 'Contact us',
    buttonVariant: 'contained',
  }
];



export default function Pricing() {
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleCheckout = async (e) => {
    e.preventDefault();
    try{
        const token = await axios.get("/api/auth/refresh-token");
        if (token.data){
            const config = {headers: {"Content-Type": "application/json", "Authorization": `Bearer ${String(token.data.accessToken)}`}};
            const sub = await axios.get("/api/auth/subscription", config);
            if (sub.data.subscription) {
                navigate("/home");
            } else {
                const session = await axios.post("/api/stripe/checkout", {priceId: "price_1Q1XVXLyZT00OH0l6Ft4HYGo", sub: "normal"}, config);
                console.log("session: ", session);
                if (session.data) {
                    console.log("session.data.url: ", session.data.url);    
                    window.open(session.data.url, "_self");
                }
            }
        } else {
            setError("Please login to continue");
        }
    } catch(err) {
        if (err.response.data.message) {
            setError(err.response.data.message);
            setTimeout(() => { setError("")}, 3000);
        } else if (err.message) {
            setError("Please login to continue");
            setTimeout(() => { setError("")}, 3000);
        }
    }
  }

  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
        Optimize Your Business with AI Powered Insights
        </Typography>
        <Typography variant="body1" color="text.secondary">
        Unlock the full potential of your data with our advanced AI tools. Whether you're a growing business or an enterprise, our solutions are designed to help you make data-driven decisions that enhance customer experience, boost sales, and streamline operations.


        </Typography>
        <Collapse in={error}>
          <Alert severity='error' sx={{ mb: 2 }}>{error}</Alert>
        </Collapse>
      </Box>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {tiers.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={tier.title === 'Enterprise' ? 12 : 6}
            md={4}
          >
            <Card
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                borderRadius: 4, // Adjust this value to make the corners rounder
                border: tier.title === 'Professional' ? '1px solid' : undefined,
                borderColor:
                  tier.title === 'Professional' ? 'primary.main' : undefined,
                background:
                  tier.title === 'Professional'
                    ? 'linear-gradient(#1c3930, #021D02)' // Dark green gradient
                    : undefined,
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: tier.title === 'Professional' ? 'grey.100' : '',
                  }}
                >
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                  {tier.title === 'Professional' && (
                    <Chip
                      icon={<AutoAwesomeIcon />}
                      label={tier.subheader}
                      size="small"
                      sx={{
                        background: (theme) =>
                          theme.palette.mode === 'light' ? '' : 'none',
                        backgroundColor: 'primary.contrastText',
                        '& .MuiChip-label': {
                          color: 'primary.dark',
                        },
                        '& .MuiChip-icon': {
                          color: 'primary.dark',
                        },
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    color: tier.title === 'Professional' ? 'grey.50' : undefined,
                  }}
                >
                  {tier.title === 'Enterprise' ? (
                    <Typography component="h3" variant="h3" sx={{ fontWeight:"bold" }}>
                      {tier.price}
                    </Typography>
                  ) : (
                    <>
                      <Typography component="h3" variant="h2">
                        ${tier.price}
                      </Typography>
                      <Typography component="h3" variant="h6">
                        &nbsp; per month
                      </Typography>
                    </>
                  )}
                </Box>
                <Divider
                  sx={{
                    my: 2,
                    opacity: 0.2,
                    borderColor: tier.title === 'Enterprise' ? 'grey.400' : 'white',
                  }}
                />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: 'flex',
                      gap: 1.5,
                      alignItems: 'center',
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color:
                          tier.title === 'Professional'
                            ? 'primary.light'
                            : 'primary.main',
                      }}
                    />
                    <Typography
                      component="text"
                      variant="subtitle2"
                      sx={{
                        color:
                          tier.title === 'Professional' ? 'white' : undefined,
                      }}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant}
                  component="a"
                  onClick={tier.title === 'Professional' ? handleCheckout : () => navigate("/contact")}
                  target="_blank"
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
