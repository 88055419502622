import React, { useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, TextField, Typography, Grid } from '@mui/material';

export default function ContactForm() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        message: ''
      });
    
      const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
    
        const mailtoLink = `mailto:dissectaisolutions@gmail.com?subject=${encodeURIComponent(formData.company)}%20-%20Sales%20Inquiry&body=Hello,%0D%0A%0D%0AMy%20name%20is%20${encodeURIComponent(formData.firstName)}%20${encodeURIComponent(formData.lastName)}.%0D%0A%0D%0AI%20am%20reaching%20out%20from%20${encodeURIComponent(formData.company)}%20and%20would%20like%20to%20discuss%20potential%20opportunities%20for%20collaboration.%0D%0A%0D%0AYou%20can%20reach%20me%20at%20${encodeURIComponent(formData.email)}.%0D%0A%0D%0AHere%20is%20a%20brief%20message%20about%20what%20I'm%20interested%20in:%0D%0A%0D%0A${encodeURIComponent(formData.message)}%0D%0A%0D%0ALooking%20forward%20to%20your%20response.%0D%0A%0D%0ABest%20regards,%0D%0A${encodeURIComponent(formData.firstName)}%20${encodeURIComponent(formData.lastName)}%0D%0A${encodeURIComponent(formData.company)}`;
    
        window.location.href = mailtoLink;
    
        // Optionally, reset the form fields
        e.target.reset();
      };
    
  return (
    <Box sx={{ height: '93.8vh', display: 'flex', alignItems: 'center', justifyContent: 'center', p: 0 }}>
      <Grid container sx={{ maxWidth: '100%', mx: 'auto', height: '100%', boxShadow: 3, borderRadius: 0 }}>
        <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', px: 4 }}>
          <Box>
            <Typography variant="h2" mt={4} fontWeight="bold" gutterBottom>
                Contact Us
            </Typography>
            <Typography variant="body1" gutterBottom>
                Reach out to our sales team for custom solutions tailored to your business. We're here to help you get the most out of our services.
            </Typography>

            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <TextField
                required
                fullWidth
                label="First name"
                name="firstName"
                margin="normal"
                variant="outlined"
                sx={{ mb: 2 }}
                onChange={handleChange}
              />
              <TextField
                required
                fullWidth
                label="Last name"
                name="lastName"
                margin="normal"
                variant="outlined"
                sx={{ mb: 2 }}
                onChange={handleChange}
              />
              <TextField
                required
                fullWidth
                label="Email address"
                name="email"
                type="email"
                margin="normal"
                variant="outlined"
                sx={{ mb: 2 }}
                onChange={handleChange}
              />
              <TextField
                required
                fullWidth
                label="Company"
                name="company"
                margin="normal"
                variant="outlined"
                sx={{ mb: 2 }}
                onChange={handleChange}
              />
              <TextField
                required
                fullWidth
                label="How can we help you?"
                name="message"
                multiline
                rows={4}
                margin="normal"
                variant="outlined"
                sx={{ mb: 2 }}
                onChange={handleChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="success"
                sx={{ mt: 3 }}
              >
                Submit
              </Button>
            </form>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 0 }}>
          <Box
            component="img"
            src="/contact.jpg" // Replace with the actual image URL or import the image
            alt="Future of the Internet"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: 0, // No rounded corners
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
