import React from 'react';
import { Box, Link, Typography, useTheme, useMediaQuery, Collapse, Alert, TextField, Button, Card, Stack} from '@mui/material';
import FormatAlignLeftRoundedIcon from '@mui/icons-material/FormatAlignLeftRounded';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import ImageSearchRoundedIcon from '@mui/icons-material/ImageSearchRounded';
import { useNavigate } from 'react-router-dom';
import axios from '../../utils/axios';
import { useState } from 'react';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';

const HomeScreen = () => {
    const navigate = useNavigate();
    const [error, setError] = useState("");
    // price_1PhM4HLyZT00OH0lQQnGmqnk",

        const handleCheckoutSummary = async (e) => {
            e.preventDefault();
            try{
                const token = await axios.get("/api/auth/refresh-token");
                if (token.data){
                    const config = {headers: {"Content-Type": "application/json", "Authorization": `Bearer ${String(token.data.accessToken)}`}};
                    const sub = await axios.get("/api/auth/subscription", config);
                    if (sub.data.subscription) {
                        navigate("/summary");
                    } else {
                        const session = await axios.post("/api/stripe/checkout", {priceId: "price_1Q1XVXLyZT00OH0l6Ft4HYGo", sub: "normal"}, config);
                        console.log("session: ", session);
                        if (session.data) {
                            console.log("session.data.url: ", session.data.url);    
                            window.open(session.data.url, "_self");
                        }
                    }
                } else {
                    setError("Please login to continue");
                }
            } catch(err) {
                console.log("Error in handleCheckoutSummary");
                if (err.response.data.message) {
                    setError(err.response.data.message);
                    setTimeout(() => { setError("")}, 3000);
                } else if (err.message) {
                    setError(err.message);
                    setTimeout(() => { setError("")}, 3000);
                }
            }
        }

        const handleCheckoutAudio = async (e) => {
            e.preventDefault();
            try{
                const token = await axios.get("/api/auth/refresh-token");
                if (token.data){
                    const config = {headers: {"Content-Type": "application/json", "Authorization": `Bearer ${String(token.data.accessToken)}`}};
                    const sub = await axios.get("/api/auth/subscription", config);
                    if (sub.data.subscription) {
                        navigate("/mp3Upload");
                    } else {
                        const session = await axios.post("/api/stripe/checkout", {priceId: "price_1Q1XVXLyZT00OH0l6Ft4HYGo", sub: "normal"}, config);
                        console.log("session: ", session);
                        if (session.data) {
                            console.log("session.data.url: ", session.data.url);    
                            window.open(session.data.url, "_self");
                        }
                    }
                } else {
                    setError("Please login to continue");
                }
            } catch(err) {
                console.log("Error in handleCheckoutAudio");
                if (err.response.data.message) {
                    setError(err.response.data.message);
                    setTimeout(() => { setError("")}, 3000);
                } else if (err.message) {
                    setError(err.message);
                    setTimeout(() => { setError("")}, 3000);
                }
            }
        }

        const handleCheckoutSales = async (e) => {
            e.preventDefault();
            try{
                const token = await axios.get("/api/auth/refresh-token");
                if (token.data){
                    const config = {headers: {"Content-Type": "application/json", "Authorization": `Bearer ${String(token.data.accessToken)}`}};
                    const sub = await axios.get("/api/auth/subscription", config);
                    if (sub.data.subscription) {
                        navigate("/salesCall");
                    } else {
                        const session = await axios.post("/api/stripe/checkout", {priceId: "price_1Q1XVXLyZT00OH0l6Ft4HYGo", sub: "normal"}, config);
                        console.log("session: ", session);
                        if (session.data) {
                            console.log("session.data.url: ", session.data.url);    
                            window.open(session.data.url, "_self");
                        }
                    }
                } else {
                    setError("Please login to continue");
                }
            } catch(err) {
                console.log("Error in handleCheckoutMarketing");
                if (err.response.data.message) {
                    setError(err.response.data.message);
                    setTimeout(() => { setError("")}, 3000);
                } else if (err.message) {
                    setError(err.message);
                    setTimeout(() => { setError("")}, 3000);
                }
            }
        }

        const handleCheckoutMarketingDocs = async (e) => {
            e.preventDefault();
            try{
                const token = await axios.get("/api/auth/refresh-token");
                if (token.data){
                    const config = {headers: {"Content-Type": "application/json", "Authorization": `Bearer ${String(token.data.accessToken)}`}};
                    const sub = await axios.get("/api/auth/subscription", config);
                    if (sub.data.subscription) {
                        navigate("/marketingDocs");
                    } else {
                        const session = await axios.post("/api/stripe/checkout", {priceId: "price_1Q1XVXLyZT00OH0l6Ft4HYGo", sub: "normal"}, config);
                        console.log("session: ", session);
                        if (session.data) {
                            console.log("session.data.url: ", session.data.url);    
                            window.open(session.data.url, "_self");
                        }
                    }
                } else {
                    setError("Please login to continue");
                }
            } catch(err) {
                console.log("Error in handleCheckoutMarketing");
                if (err.response.data.message) {
                    setError(err.response.data.message);
                    setTimeout(() => { setError("")}, 3000);
                } else if (err.message) {
                    setError(err.message);
                    setTimeout(() => { setError("")}, 3000);
                }
            }
        }

        return (
            <Box
  sx={{
    height: "95vh",       // Full height of the viewport (optional, if you want to cover full height)
    backgroundColor: "#f4f4ec", // Dark blue background
    padding: 0,            // Remove any default padding
    margin: 0,             // Remove any default margin
    mt: -2,                 // Remove default margin-top
  }}
>
                <Box display="flex" justifyContent="center">
                    <Typography fontWeight="bold" variant="h1" my={2} mt={8} sx={{color: "#1c3930"}}>
                        Dissect AI's Toolbox
                    </Typography>
                </Box>
        
                <Collapse in={error}>
                    <Alert severity='error' sx={{ mb: 2 }}>{error}</Alert>
                </Collapse>
        
                <Box display="flex" justifyContent="center" sx={{ mb: 2 }}>
                    <Typography ml={4} fontWeight="bold" variant="h4" my={0} sx={{color: "#1c3930"}}>Document Analysis</Typography>
                </Box>
        
                <Stack display="flex" justifyContent="center" direction="row" spacing={6} ml={4} sx={{ mb: 2 }}>
                    <Card onClick={handleCheckoutSummary}
                        sx={{ boxShadow: 2, borderRadius: 5, height: 220, width: 280, '&:hover': { border: 2, boxShadow: 0, borderColor: "primary.dark" }, cursor: "pointer" }}>
                        <DescriptionRoundedIcon sx={{ fontSize: 80, color: "#588d67", mt: 2, ml: 2 }} />
                        <Stack p={3} pt={0}>
                            <Typography fontWeight="bold" variant="h5">Reviews Analyzer</Typography>
                            <Typography variant="h6">Gain actionable insights on how to improve your business/product based on user reviews</Typography>
                        </Stack>
                    </Card>
        
                    <Card onClick={handleCheckoutMarketingDocs}
                        sx={{ boxShadow: 2, borderRadius: 5, height: 220, width: 280, '&:hover': { border: 2, boxShadow: 0, borderColor: "primary.dark" }, cursor: "pointer" }}>
                        <TrendingUpIcon sx={{ fontSize: 80, color: "#588d67", mt: 2, ml: 2 }} />
                        <Stack p={3} pt={0}>
                            <Typography fontWeight="bold" variant="h5">Marketing Consultant</Typography>
                            <Typography variant="h6">Gain marketing insights based on potential clientele demographics and desires</Typography>
                        </Stack>
                    </Card>
                </Stack>
        
                <Box display="flex" justifyContent="center" sx={{ mb: 2 }}>
                    <Typography ml={4} fontWeight="bold" variant="h4" my={2} sx={{color: "#1c3930"}}>Audio Analysis</Typography>
                </Box> 
        
                <Stack display="flex" justifyContent="center" direction="row" spacing={6} ml={4}>
                    <Card onClick={handleCheckoutAudio}
                        sx={{ ml: 4, boxShadow: 2, borderRadius: 5, height: 220, width: 280, '&:hover': { border: 2, boxShadow: 0, borderColor: "primary.dark" }, cursor: "pointer" }}>
                        <SupportAgentIcon sx={{ fontSize: 80, color: "#588d67", mt: 2, ml: 2 }} />
                        <Stack p={3} pt={0}>
                            <Typography fontWeight="bold" variant="h5">Customer Service Call</Typography>
                            <Typography variant="h6">Gain actionable insights on your company's customer service performance based on your call files</Typography>
                        </Stack>
                    </Card>
        
                    <Card onClick={handleCheckoutSales}
                        sx={{ ml: 4, boxShadow: 2, borderRadius: 5, height: 220, width: 280, '&:hover': { border: 2, boxShadow: 0, borderColor: "primary.dark" }, cursor: "pointer" }}>
                        <PointOfSaleIcon sx={{ fontSize: 80, color: "#588d67", mt: 2, ml: 2 }} />
                        <Stack p={3} pt={0}>
                            <Typography fontWeight="bold" variant="h5">Sales Call</Typography>
                            <Typography variant="h6">Gain actionable insights on your company's sales performance based on your call files</Typography>
                        </Stack>
                    </Card>
                </Stack>
            </Box>
        );
        
}

export default HomeScreen;
