import React, { useState } from "react";
import {
  Box,
  Link,
  Typography,
  useTheme,
  useMediaQuery,
  Collapse,
  Alert,
  TextField,
  Button,
  Stack,
  Card,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import axios from '../../utils/axios';
import { useNavigate } from "react-router-dom";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import { Document } from "docx";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import CustomAccordian from "../CustomAccordian"; // Import the CustomAccordion component
import FileUploadButton from "../FileUploadButton"; // Import the FileUploadButton component
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const SummaryScreen = () => {
  const theme = useTheme();
  const isNotMobile = useMediaQuery("(min-width: 1000px)");
  const navigate = useNavigate();

  const [text, setText] = useState("");
  const [error, setError] = useState("");
  const [summary, setSummary] = useState("");
  const [loading, setLoading] = useState(false); // Loading state for file processing
  const [loadingSummary, setLoadingSummary] = useState(false); // Loading state for summary generation

  const handleCopyToClipboard = () => {
    if (summary) {
      const allInsights = summary.split(/(?=\d+\.)/).join("\n");
      navigator.clipboard.writeText(allInsights).catch((err) => {
        console.error("Failed to copy: ", err);
      });
    }
  };

  const summaryHandler = async (e) => {
    e.preventDefault();
    setLoadingSummary(true); // Set loading to true when summary processing starts

    try {
      const { data } = await axios.post("/api/openai/summary", { text });
      console.log(data);
      setSummary(data);
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data.error) {
        setError(error.response.data.error);
      } else if (error.message) {
        setError(error.message);
      }
      setTimeout(() => {
        setError("");
      }, 5000);
    } finally {
      setLoadingSummary(false); // Set loading to false after processing is done
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLoading(true); // Set loading to true when file processing starts

      const reader = new FileReader();
      const fileType = file.type;

      reader.onload = async (event) => {
        const fileContent = event.target.result;

        if (fileType === "text/plain") {
          setText(fileContent);
        } else if (fileType === "application/pdf") {
          const pdf = await pdfjsLib.getDocument({ data: fileContent }).promise;
          let extractedText = "";
          for (let i = 0; i < pdf.numPages; i++) {
            const page = await pdf.getPage(i + 1);
            const textContent = await page.getTextContent();
            textContent.items.forEach((item) => {
              extractedText += item.str + " ";
            });
          }
          setText(extractedText);
        } else if (
          fileType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          const doc = new Document(fileContent);
          const docText = doc.paragraphs
            .map((paragraph) => paragraph.text)
            .join(" ");
          setText(docText);
        } else if (
          fileType ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          const workbook = XLSX.read(fileContent, { type: "array" });
          let excelText = "";
          workbook.SheetNames.forEach((sheetName) => {
            const sheet = workbook.Sheets[sheetName];
            const sheetText = XLSX.utils.sheet_to_csv(sheet);
            excelText += sheetText;
          });
          setText(excelText);
        } else if (fileType === "text/csv") {
          Papa.parse(file, {
            complete: function (results) {
              const csvText = results.data
                .map((row) => row.join(", "))
                .join("\n");
              setText(csvText);
            },
            error: function (error) {
              setError("Error parsing CSV file: " + error.message);
            },
          });
        } else {
          setError(
            "Unsupported file format. Please upload a .txt, .pdf, .docx, .xlsx, or .csv file."
          );
        }

        setLoading(false); // Set loading to false after file processing is done
      };

      if (fileType === "text/plain" || fileType === "text/csv") {
        reader.readAsText(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
    }
  };

  return (
    <Box
      width={isNotMobile ? "50%" : "90%"}
      p="2rem"
      m="2rem auto"
      borderRadius={5}
      backgroundColor={theme.palette.background.alt}
      sx={{ boxShadow: 5 }}
    >
      <Collapse in={error}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      </Collapse>
      <form onSubmit={summaryHandler}>
        <Typography variant="h3" mb={2}>
          Reviews Analyzer
        </Typography>
        <Stack direction="row" spacing={1}>
          <Box width="87%">
            <TextField
              placeholder="Enter document text you want to analyze here"
              required
              fullWidth
              value={text}
              onChange={(e) => setText(e.target.value)}
            ></TextField>
          </Box>
          <Button
            disableElevation
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: "#588d67", // Initial background color
              color: "white", // Text color
              "&:hover": {
                backgroundColor: "#1c3930", // Background color on hover
              },
            }}
          >
            Analyze
          </Button>
        </Stack>
        <Typography variant="h6" mt={2}>
          Or upload a file:
        </Typography>
        <FileUploadButton handleFileChange={handleFileChange} />
      </form>

      {loading && (
        <Box sx={{ width: "100%", mt: 2 }}>
          <LinearProgress />
        </Box>
      )}

      {loadingSummary && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {summary ? (
        <Box sx={{ mt: 4 }}>
          <Button
            variant="contained"
            startIcon={<ContentCopyIcon />}
            onClick={handleCopyToClipboard}
            sx={{
              mt: -2,
              mb: 2,
              backgroundColor: "#588d67", // Initial background color
              color: "white", // Text color
              "&:hover": {
                backgroundColor: "#1c3930", // Background color on hover
              },
            }}
          >
            <Typography variant="button" sx={{ color: "white" }}>
              Copy Insights
            </Typography>
          </Button>
          <CustomAccordian summary={summary} />{" "}
          {/* Use the CustomAccordion component */}
        </Box>
      ) : (
        !loadingSummary && ( // Ensure the placeholder is not shown while loading summary
          <Card
            sx={{
              mt: 4,
              p: 2,
              border: 1,
              boxShadow: 0,
              borderColor: "neutral.medium",
              borderRadius: 2,
              height: "500px",
              bgcolor: "background.default",
            }}
          >
            <Typography
              variant="h3"
              color="neutral.main"
              sx={{
                textAlign: "center",
                verticalAlign: "middle",
                lineHeight: "450px",
              }}
            >
              Insights will appear here
            </Typography>
          </Card>
        )
      )}

      <Typography mt={2}>
        Not the tool you were looking for? <Link href="/home">Go back</Link>
      </Typography>
    </Box>
  );
};

export default SummaryScreen;
