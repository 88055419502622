import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function Hero() {
  const navigate = useNavigate();

  const handleStartNowClick = () => {
    navigate('/home');
  };

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #C7F7C7 10%, #f4f4ec)' // More prominent light green to offwhite
            : `linear-gradient(#1c3930, ${alpha('#021D02', 0.0)})`, // Dark green to transparent
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)',
            }}
          >
            Analyze with&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: 'clamp(3rem, 10vw, 4rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
              Dissect
            </Typography>
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}
          >
            Explore our AI-driven tool dashboard, delivering high-quality solutions
            tailored to business owners. Optimize your business with our all-in-one AI consultant.
          </Typography>
          
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
          >
            <Button variant="contained" color="primary" onClick={handleStartNowClick}>
              Start now
            </Button>
          </Stack>
          <img
            src="/Logo.svg"
            alt="Dissect AI Logo"
            style={{ width: 300, mt: -20, mb: 20, alignSelf: 'center' }}
          />
        </Stack>
        <Box
          id="image"
          sx={(theme) => ({
            mt: { xs: 8, sm: 10 },
            alignSelf: 'center',
            height: { xs: 200, sm: 700 },
            width: '100%',
            backgroundImage:
              theme.palette.mode === 'light'
                ? 'url("/hero2.png")'
                : 'url("/hero2.png")',
            backgroundSize: 'cover',
            borderRadius: '10px',
            outline: '1px solid',
            outlineColor:
              theme.palette.mode === 'light'
                ? alpha(theme.palette.primary.main, 0.5) // Light mode outline (greenish)
                : alpha('#FFFFFF', 0.2), // Dark mode outline (whitish)
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 12px 8px ${alpha(theme.palette.primary.main, 0.2)}` // Light mode shadow (greenish glow)
                : `0 0 24px 12px ${alpha('#FFFFFF', 0.2)}`, // Dark mode shadow (whitish glow)
          })}
        />
      </Container>
    </Box>
  );
}
